<template>
  <div>
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="content">
      <div id="login">
        <img class="login_text" src="/subject/invest/login_text.png" alt="">
        <div class="interactive">
          <input v-model="form.phone" type="text" placeholder="请输入手机号">
          <div class="codeBox">
            <input v-model="form.code" type="text" placeholder="请输入验证码">
            <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
            <span v-else>重新获取({{verSecond}})</span>
          </div>
          <button @click="submit(form)">一键领取</button>
        </div>
        <span class="dollar">更有50美元惊喜等你领</span>
        <div v-if="controlImgCodeDialog" class="codeDialog">
          <img :src="imgcodelocal" alt="">
          <p>
            <span>图形验证码:</span>
            <input type="text" v-model="form.codeNumber">
          </p>
          <div>
            <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
            <button @click="confirm(form.codeNumber)">确定</button>
          </div>
        </div>
      </div>
      <img class="modelTitleImg" src="/subject/invest/title_4.png" alt="">
      <div class="model_1">
        <img class="model_1_img" src="/subject/invest/model_1_text.png" alt="">
        <img @click="toTop" class="model_1_button" src="/subject/invest/model_1_button.png" alt="">
        <img class="model_1_img" src="/subject/invest/model_1_img.png" alt="">
      </div>
      <img class="modelTitleImg modelTitleImg_2" src="/subject/invest/title_1.png" alt="">
      <div class="model model_2">
        <img @click="toTop" class="positionButton" src="/subject/invest/model_button.png" alt="">
      </div>
      <img class="modelTitleImg modelTitleImg_2" src="/subject/invest/title_2.png" alt="">
      <div class="model model_3">
        <p @click="rulesModel = true">活动规则</p>
        <img @click="toTop" class="positionButton" src="/subject/invest/model_button.png" alt="">
      </div>
      <img class="modelTitleImg modelTitleImg_2" src="/subject/invest/title_3.png" alt="">
      <div class="model model_4">
        <img @click="toTop" class="positionButton" src="/subject/invest/model_button.png" alt="">
      </div>
    </div>
    <div class="popup" v-if="rulesModel">
      <img
        class="guanbi1"
        @click="rulesModel = false"
        src="../../../../static/common/user-info-close.png"
        alt=""
      />
      <ul class="dialogContent">
        <h3>活动规则</h3>
        <li>
          <span>活动对象：</span>
          活动期间，在本平台开立真实账户，并首次入金的客户。
        </li>
        <li>
          <span>活动时间：</span>
          即日起至2023年04月30日23:59
        </li>
        <li>
          <span>活动规则：</span><br/>
          活动期间，新用户可享双重福利：<br/>
          1. 开户赠送$500现金券一张，盈利即送3美金；<br/>
          2. 首次入金，充多少送多少，交易可返现，<br/>
          <label style="color:#0f7361">上不封顶</label>
        </li>
        <li>
          <span>开户领现金券活动</span><br/>
          1. 活动期间，开立真实账户赠送500美元现金券一张；<br/>
          2. 现金券领取后可直接交易，使用有效期从当日00:00起计算，共计3天；<br/>
          3. 每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br/>
          4. 现金券交易后盈利即送3美金，直接发放到MT账户，用户可直接提现；<br/>
          5. 盈利有效期为平仓后30天内，逾期扣回；<br/>
          6. 现金券仅限伦敦金/伦敦银交易品种；
        </li>
        <li>
          <span>首入金 充多少送多少</span><br/>
          首次充值，充多少送多少美元赠金，奖励上不封顶；<br/>
          72小时内总入金≥500美元，可额外获得100美元现金；<br/>
        </li>
        <li>
          <span>首入金奖励如下：</span>
          <table>
          <tr>
            <th>层级</th>
            <th>首入条件(72h内)</th>
            <th>美元赠金(100%赠送)</th>
            <th>美元现金(限时送)</th>
            <th>美元现金提现条件(30天内)</th>
            <th>总获赠金额</th>
          </tr>
          <tr>
            <th>1</th>
            <th>总入金＜$500</th>
            <th>＜$500</th>
            <th>无</th>
            <th>无</th>
            <th>＜500美元赠金</th>
          </tr>
          <tr>
            <th>2</th>
            <th>总入金≥$500</th>
            <th>≥$500</th>
            <th>100</th>
            <th>≥10手</th>
            <th>≥500美元赠金+100美元现金</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>充多少 送多少</th>
            <th>100</th>
            <th></th>
            <th></th>
          </tr>
        </table>
        </li>
        <li>
          <span>美元赠金：</span><br/>
          1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br/>
          2、 首入金有效期为第一笔入金后的72h内，赠送金额将按照72h后最终数额计算；<br/>
          3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br/>
          4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br/>
          <label style="color:#0f7361">更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。</label>
        </li>
        <li>
          <span>美元现金：</span><br/>
          1、美元现金直接入账，可交易，可提现；<br/>
          2、用户在30天内完成≥10手交易即可提现，品种限伦敦金/伦敦银；<br/>
          3、30天内未完成手数要求，活动到期时，系统仅扣回美元现金金额。<br/>
          举例说明：A女士，入金520美元，获得100美元现金，账户实际到账金额为520+100=620美元，第15天，A女士完成10手交易，顺利提取100美元现金。
        </li>
        <li>
          <span>注意事项：</span><br/>
          1. 客户参与活动需在本公司开立真实交易账户，参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br/>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br/>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br/>
          4. 凡推广商及其名下客户不参与本活动；<br/>
          5.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <contactus-vue></contactus-vue>
    <div class="shadow" v-if="controlImgCodeDialog || rulesModel"></div>
  </div>
</template>

<script>
import { getGuid } from '../../../../utils/guid';
import { verifyImgCode, quickRegister } from '../../../../api/info';
import ContactusVue from "../../Contactus.vue";
export default {
  name:'investWap',
  components:{
    ContactusVue
  },
  data(){
    return{
      navBarFixed: false,
      scroll: '',
      screenWidth: null,
      form:{
        phone:'',
        code:'',
        codeNumber:'',
      },
      // 控制显示验证码或读秒
      verControl:true,
      // 定时器倒计时
      refreshData:null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      slider:true,
      rulesModel: false,
      isApp: false,
    }
  },
  created(){
    this.$emit("controlShow", true);
    if(this.$route.query.isApp){
      this.isApp = true;
    };
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "investPc",
        });
      }
    },
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "investPc",
        });
      }
    },
  },
  methods:{
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          }else{
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      if(form.phone && form.code){
        let data = {
          IsQuickRregister:true,
          Mobile:form.phone,
          Code:form.code,
          Token:this.guid,
          LoginType:1,
          AuthMode:1,
        };
        quickRegister(data).then(res=>{
          console.log(res);
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            window.location.href = 'https://www.rlcvipltd.net/uc/login';
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
      }
    },
    // 点击获取验证码
    verCode(attr){
      if(this.isPhone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://sem.rlcmarketltd.net' + '/api/proxy/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  }
}
</script>

<style lang="less" scoped>
  .tabbar {
    width: 100%;
    background-color: #ffffff;
    img {
      margin: 10px 30px;
      width: 130px;
    }
  }
  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .content{
    width: 100%;
    height: 2822px;
    background: url('/subject/invest/wap_bj.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    #login{
      width: 90%;
      height: 276px;
      background: url('/subject/invest/login_bj_wap.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-top: 325px;
      p{
        font-size: 22px;
        font-weight: 600;
        color: #ff8038;
        margin: 10px auto;
      }
      .login_text{
        width: 70%;
        margin-bottom: 20px;
      }
      .interactive{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        input:focus{
          outline: none;
        }
        input{
          width: 80%;
          height: 35px;
          background: #fff;
          border: 1px solid #ff6622;
          border-radius: 10px;
          font-size: 16px;
          margin-bottom: 20px;
          text-indent: 15px;
          list-style: none;
        }
        .codeBox{
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            width: 100px;
            height: 37px;
            line-height: 37px;
            font-size: 14px;
            border-radius: 9px;
            background: linear-gradient(to left, #f33c3a, #ff7765);
            text-align: center;
            color: #fff;
            position: absolute;
            top: 1px;
            right: 31px;
            cursor: pointer;
          }
        }
        button{
          width: 72%;
          height: 38px;
          background: linear-gradient(to left, #f33c3a, #ff7765);
          border: 1px solid #ff8038;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
      }
      .dollar{
        font-size: 16px;
        color: #f28b50;
        margin-top: 10px;
      }
      .codeDialog{
        width: 85%;
        height: 200px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #fee6e4;
        border-radius: 15px;
        z-index: 99;
        border: 1px solid #ffdbdb;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img{
          width: 120px;
          margin-bottom: 0;
        }
        p{
          span{
            color: #5f0e15;
            font-size: 15px;
            font-weight: 600;
          }
          input{
            width: 150px;
            height: 30px;
            background-color: #ffffff;
            border: none;
            margin-left: 10px;
            text-indent: 10px;
            color: #5f0e15;
            font-size: 14px;
          }
        }
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          button{
            width: 80px;
            height: 30px;
            border: 1px solid #f64b44;
            background-color: #ffffff;
            color: #f64b44;
            font-size: 14px;
            border-radius: 5px;
            margin: 0 10px;
          }
          button:nth-child(2){
            background-color: #f64b44;
            color: #ffffff;
          }
        }
      }
    }
    .modelTitleImg{
      width: 95%;
      display: inline-block;
      margin: 10px auto -115px;
    }
    .model_1{
      width: 100%;
      height: 795px;
      background: url('/subject/invest/model_1_wap.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .model_1_img{
        width: 75%;
      }
      .model_1_button{
        width: 70%;
        margin-top: 15px;
      }
    }
    .modelTitleImg_2{
      margin: -80px auto -115px;
    }
    .model{
      width: 100%;
      position: relative;
      .positionButton{
        position: absolute;
        bottom: 93px;
        left: 50%;
        transform: translate(-50%,0);
        width: 65%;
      }
    }
    .model_2{
      height: 567px;
      background: url('/subject/invest/model_2_wap.png') no-repeat;
      background-size: 100% 100%;
    }
    .model_3{
      height: 678px;
      background: url('/subject/invest/model_3_wap.png') no-repeat;
      background-size: 100% 100%;
      P{
        position: absolute;
        top: 135px;
        left: 50%;
        transform: translate(-50%,0);
        color: #86635d;
        font-size: 14px;
        text-decoration: underline;
      }
    }
    .model_4{
      height: 531px;
      background: url('/subject/invest/model_4_wap.png') no-repeat;
      background-size: 100% 100%;
      .positionButton{
        bottom: 35px;
      }
    }
  }
  .shadow{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(17, 17, 17, 0.4);
  }
  .popup {
    width: 90%;
    height: 400px;
    border-radius: 5px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%;-46%);
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
    overflow-y: scroll;
    z-index: 99999999999999;
    .guanbi1 {
      float: right;
      cursor: pointer;
      width: 15px;
      margin-top: 5px;
      margin-right: 5px;
    }
    .dialogContent {
      box-sizing: border-box;
      padding: 10px 10px 10px;
      h3{
        text-align: center;
        margin: 0px auto 10px;
        font-size: 15px;
        color: #f33c3a;
      }
      li {
        font-size: 14px;
        color: rgb(34, 33, 33);
        line-height: 24px;
        span {
          font-weight: 600;
          font-size: 14px;
          color: #f33c3a;
        }
        table {
          width: 103%;
          border: 1px solid #000;
          border-radius: 8px;
          tr {
            th {
              text-align: center;
              font-weight: 500;
              border-bottom: 1px solid #000;
              border-right: 1px solid #000;
              padding: 2px;
              color: #000;
              font-size: 14px;
            }
            th:last-child {
              border-right: 1px solid transparent;
            }
          }
          tr:nth-child(1) {
            th {
              font-size: 14px;
              color: #000;
            }
          }
          tr:last-child {
            th {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width:375px){
    .content{
      height: 2945px;
      #login{
        height: 300px;
      }
      .model_1{
        height: 820px;
        .model_1_img{
          width: 67%;
        }
      }
      .model_2{
        height: 590px;
      }
      .model_3{
        height: 700px;
      }
      .model_4{
        height: 550px;
      }
    }
  }
</style>